import BackgroundDisplay from "../components/background-display";

const Membership = () => {
    return (  
        <div>
           <BackgroundDisplay title="Membership"/>

        <div className="Membership-Container">
           <div> <h2>FELLOW - ORIFL</h2></div>
           <div>
                <ul><li>Applicants must be an eminent personality and demonstrate a remarkable commitment to promoting the knowledge, method, practice and application of Operations Research in their chosen career. <br></br><strong>Or</strong><br></br> Have been an Associate–Fellow of the Institute for five (5) years and <br></br>(i) Have undergone the Institute’s Mandatory professional Development training at least twice;<br></br>(ii) Be up–to–date in the payment of their annual membership subscription and development levy.<br></br> (iii) Be involved in the activities of the Institute at the National or District Level. This includes attending professional and Business meetings, serving on committees, or at seminars/workshops or facilitating seminars/workshops and other training programs. <br></br><strong>Note: </strong>The power of election to this status is vested in the Council of the Institute on the membership committee’s recommendation.
</li></ul>
           </div>
           <div> <h2>ASSOCIATE FELLOW - ORIAF</h2></div>
           <div>
                <ul><li>Fulfil any of the following: <br></br> a. (i) Be an eminent personality as evidenced by their contribution in the area of their chosen career <br></br> (ii) Obtained a Master’s Degree in any field with evidence of research work. <br></br> (iii) Evidence of ten (10) years  working experience after the first Degree/HND <br></br> <strong>Or</strong> <br></br> (b) Have been an Associate of the Institute for a minimum period of five (5) years and: <br></br>(i) Have undergone the Institute’s Mandatory Professional Development Program (MPDP) at least twice. <br></br>(ii) Be up–to–date in the payment of the annual membership subscriptions and development levy<br></br> (iii) Be involved in the activities of the Institute at the national or chapter level. These include attendance of professional and business meetings, serve on the committees, or at seminars/ workshops and other training programs.</li></ul>
           </div>
           <div> <h2>ASSOCIATE MEMBER - ORIAM</h2></div>
           <div>
                <ul>
                    <li>Applicants must have: <br></br>(a) Age not less than 25 years<br></br> (b) University Degree/HND in any field of knowledge or other professional qualification acceptable to the Council of the Institute.<br></br> (c) A minimum of three (3) years  working experience.<br></br> <strong>Or</strong><br></br> (a) Having three (3) years post-election as a graduate member with working experience within the period <br></br> (b) Up–to–date in financial obligation to the Institute. <br></br>(c) Must have undergone the Institute’s Mandatory Professional Development Program (MPDP) at the time of application.</li>
                    
                </ul>
           </div>
           <div> <h2>GRADUATE MEMBER - ORIGM</h2>
          
           <div>
                <ul>
                    <li>Applicants must have: <br></br> (a) University Degree/HND in any field of knowledge <br></br><strong>Or</strong><br></br> (b) Other professional qualification acceptable to the Council of the Institute.</li>
                  
                </ul>
           </div>
           <div> <h2>STUDENT MEMBER - ORISM</h2></div>
           <div>
                <ul>
                    <li>This category applies to the student of any tertiary institution.</li>
                    
                </ul>
           </div>
          
           
           <div> <h2>HONORARY MEMBER - ORIHM</h2></div>
           <div>
                <ul>
                    <li>This category is an honorary position for outstanding individuals</li>
                    
                </ul>
           </div>
           <div> <h2>CORPORATE MEMBER BUSINESS NAME - ORIBN</h2></div>
           <div>
                <ul>
                    <li>This categories applies to corporate bodies registered as a business name </li>
                    
                </ul>
           </div>
           <div> <h2>CORPORATE  MEMBER LTD/GTE-ORILG</h2></div>
           <div>
                <ul>
                    <li>This categories applies to corporate bodies registered as a limited company by guarantee </li>
                    
                </ul>
           </div>
           <div> <h2>CORPORATE  MEMBER LTD/SHARES-ORILS</h2></div>
           <div>
                <ul>
                    <li>This categories applies to corporate bodies registered as a limited company by share holders </li>
                    
                </ul>
           </div>

        </div>
        </div>
        </div>
    );
}
 
export default Membership;