import {  Link } from 'react-router-dom';
import { WhatsAppOutlined, FacebookFilled, InstagramOutlined, TwitterSquareFilled, GoogleOutlined, PhoneOutlined, InboxOutlined, AimOutlined, MessageOutlined} from '@ant-design/icons';
import logo from '../images/logoWhite.png';

const Footer = () => {
    return ( 
        <div className="Overall-Footer-Container">
            <div className="Footer-Container">
                <div>
                    <img src={logo} alt='Logo' width='160px' height='33px'/> <small>RC - 6952853</small><br></br>
                    <p>ORIDSAN is the Professional body for Students, Researchers, Policy-makers and Decision Analysts who applies Operations Research models in real-life Decision-making Problems.</p>
                    {/* <div><h5> RC - 6952853 </h5></div> */}
                  
                    <div>
                        {/* <p>Operations Research Institute for Decision Science & Analytics of Nigeria </p> */}
                       
                    </div>
                </div>
                <div>
                    <div>
                        <div>
                            <h3>Quick Links</h3>
                        </div>
                        <div className='Footer-Links'>
                        <div> <Link to='/'>Home</Link></div>
                            <div> <Link to='/about'>About</Link></div>
                             <div><Link to='/affiliation'>Affiliation</Link></div>
                             <div> <Link to='/membership'>Membership</Link></div>
                            <div> <Link to='/chapters'>Chapters</Link></div>
                             {/* <div><Link to='/conference'>Conferences</Link></div> */}
                             {/* <div> <Link to='/membership'>Membership</Link></div>
                            <div> <Link to='/chapters'>Chapters</Link></div>
                             <div><Link to='/conference'>Conferences</Link></div> */}
                            
                        </div>
                    </div>
                </div>
                {/* <div>
                    <div>
                        <div>
                            <h3>More Links</h3>
                        </div>
                        <div className='Footer-Links'>
                            <div> <Link to='/'>Home</Link></div>
                            <div> <Link to='/about'>About</Link></div>
                             <div><Link to='/affiliation'>Affiliation</Link></div>
                             <div> <Link to='/membership'>Membership</Link></div>
                            <div> <Link to='/chapters'>Chapters</Link></div>
                             <div><Link to='/conference'>Conferences</Link></div>
                            
                            
                        </div>
                    </div>
                </div> */}
               
                <div>
                    <div><h3>Get in touch</h3></div>
                    <div>
                        {/* <p>Terms and conditions apply</p> */}
                       <p><AimOutlined/> No. 21A, Ifokanbale Community, Lamodi, OFFA. Kwara State Nigeria
                        </p>
                        <p><InboxOutlined/> info@oridsan.org.ng</p>
                        <p><PhoneOutlined/> <strong>+234 706 - 730 - 4585</strong></p>
                        
                    </div>
                    <div >
                    <WhatsAppOutlined style={{padding: 10, fontSize: 20,}}/>
                    <FacebookFilled style={{padding: 10, fontSize: 20,}}/>
                    <InstagramOutlined style={{padding: 10, fontSize: 20,}}/>
                    <TwitterSquareFilled style={{padding: 10, fontSize: 20,}}/>
                    <GoogleOutlined style={{padding: 10, fontSize: 20,}}/>
                    </div>
                </div>
            </div>
            {/* <center>
                <div>
                    <span>Powered by Nutscoders Tech</span>
                </div>
            </center> */}
        </div>
     );
}
 
export default Footer;