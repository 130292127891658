import styled from 'styled-components'
import BackgroundDisplay from "../components/background-display";
import useFetch from '../Networks/useFetch';
import { Endpoint } from '../components/endpoint';
import { PhoneOutlined, AimOutlined, LoadingOutlined} from '@ant-design/icons';


const ChapterTable = styled.table`
    width: 80%;
    margin: 0 auto;
    // background-color: green;
    // color: white;
    margin-top:50px;
    padding: 40px;
    th{
        text-align:left;
    }
    tr, td{
        text-align: left;
        padding: 5px;
    }
    
    }
    @media screen and (max-width: 850px){
        ChapterTable{
         width: 100%;
         background-color: red;
        }

`

const Chapters = () => {
    const{data: isChapter, isLoading} = useFetch(`${Endpoint}/admin/chapters`);
    return ( 
       

        <div>
            <BackgroundDisplay title='Chapters'/>
            <div className='Chapter-Container'>
            {isChapter && isChapter.map((chapter)=>{
                
                return(
                  
                    <div className='Chapter-Card' key={chapter._id}>
                        <div><h4>{chapter.chapterName}</h4></div>
                        <div><AimOutlined style={{color: '#2E7159'}}/><span> {chapter.address} </span></div>
                        <div><PhoneOutlined style={{color: '#2E7159'}}/><span> {chapter.contact}</span></div>
                    </div>
                
                )
            })}
            </div>
    
            {isLoading && <div><LoadingOutlined style={{color: '#2E7159', fontSize:33}}/>Loading..</div>}
           
        </div>
     );
}
 
export default Chapters;