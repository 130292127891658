export const months = (rawMonth) =>{
    console.log(rawMonth);
    if(rawMonth === '01'){
      return `Jan`
    }
    if(rawMonth === '02'){
      return `Feb`
    }
    if(rawMonth === '03'){
      return `Mar`
    }
    if(rawMonth === '04'){
      return `Apr`
    }
    if(rawMonth === '05'){
      return `May`
    }
    if(rawMonth === '06'){
      return `Jun`
    }
    if(rawMonth === '07'){
      return `Jul`
    }
    if(rawMonth === '08'){
      return `Aug`
    }
    if(rawMonth === '09'){
      return `Sep`
    }
    if(rawMonth === '10'){
      return `Oct`
    }
    if(rawMonth === '11'){
      return `Nov`
    }
    if(rawMonth === '12'){
      return `Dec`
    }
  }