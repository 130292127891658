import { BrowserRouter, Routes, Route} from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import About from './pages/about';
import Navbar from './components/nav';
import Footer from './components/footer';
import './App.css';
import Contact from './pages/contact';
import Membership from './pages/membership';
import Publication from './pages/publication';
import Affiliation from './pages/affiliation';
import Chapters from './pages/chapter';
import AllConferences from './pages/allConferences';
import AllNews from './pages/All-news';
import DetailNews from './pages/Detail-news';




function App() {
 
  
  return (
    <div className="App">
      <BrowserRouter>
          <Navbar/>
                <Routes>
                <Route path='/' element={<Home/>}/>
                <Route path='/about' element={<About/>}/>
                <Route path='/membership' element={<Membership/>}/>
                <Route path='/publication' element={<Publication/>}/>
                <Route path='/conference' element={<AllConferences/>}/>
                <Route path='/contact' element={<Contact/>}/>
                <Route path='/affiliation' element={<Affiliation/>}/>
                <Route path='/chapters' element={<Chapters/>}/>
                <Route path='/news' element={<AllNews/>}/>
                <Route path='/news/:id' element={<DetailNews/>}/>
                </Routes>
                <Footer/>
          </BrowserRouter>
    </div>
 
  );
}

export default App;
