import picture1 from '../images/president.jpeg';
import registrar from '../images/registrar.jpeg';
import vp1 from '../images/vp1.png';
import Philips from '../images/philips.jpeg';
import Lawal from '../images/lawal.jpeg';
import webmaster from '../images/webmaster.jpeg';
import avatar from '../images/Profile-Avatar.png';
const ExecutiveCouncil = () => {
    return ( 
        <div className='executive-council-container'>
          <div><h2><center>National Executive Council</center></h2></div>
          <div className="container-row">
            <div className='container-row-inner'>
                <div><img src={picture1} alt="" /></div>
                <div><h3>Dr. Umar Muhammad Modibbo</h3><span>President and Chairman of Council</span></div>
            </div>
            <div className='container-row-inner'>
                <div><img src={registrar} alt="" /></div>
                <div><h3>Dr. Joseph David Olusegun</h3><span>CEO and Registrar to the Council</span></div>
            </div>
            <div className='container-row-inner'>
                <div><img src={vp1} alt="" /></div>
                <div><h3>Dr. Cynthia Oruime</h3><span>1st Vice president</span></div>
            </div>
            <div className='container-row-inner'>
                <div><img src={avatar} alt="" /></div>
                <div><h3>Dr. Mohammed Yeldu</h3><span>2nd Vice President</span></div>
            </div>
            <div className='container-row-inner'>
                <div><img src={avatar} alt="" /></div>
                <div><h3>Dr. Olusegun Opaleke</h3><span>Honarary Treasurer</span></div>
            </div>
            <div className='container-row-inner'>
                <div><img src={Philips} alt="" /></div>
                <div><h3>Dr. Philips Obasohan</h3><span>Ex-Officio</span></div>
            </div>
            <div className='container-row-inner'>
                <div><img src={Lawal} alt="" /></div>
                <div><h3>Chief Abdulfatai Lawal  </h3><span>Ex-Officio</span></div>
            </div>
            <div className='container-row-inner'>
                <div><img src={avatar} alt="" /></div>
                <div><h3>Prof. Bola Kadiri</h3><span>Ex-Officio</span></div>
            </div>
            <div className='container-row-inner'>
                <div><img src={avatar} alt="" /></div>
                <div><h3>Mr Muhyideen</h3><span>Web Administrator</span></div>
            </div>
            <div className='container-row-inner'>
                <div><img src={webmaster} alt="" /></div>
                <div><h3>Mr. Mohammad Mijinyawa</h3><span>Web Master</span></div>
            </div>
          </div>
        </div>
     );
}
 
export default ExecutiveCouncil;