import user from "../images/account.png";
import locationIcon from "../images/Union.png";
import chapterIcon from "../images/folder-active.png";
import training from '../images/training.png';
import award from '../images/award.png';
import seminar from '../images/seminar.jpeg';

const Services = () => {
    return ( 
        <>
        <div className="Services-Overall-Container" >
            <div className="Headings-Services">
             <div><h2 className="What-we-do">Services We Offered<br></br></h2></div>
          
         </div>
        <div className="Services-Container" style={{textAlign:'center'}}>
           
            <div className="Services-Card-Container">
                <div className="Services-Card">
                   <div><img src={seminar} alt=""/></div> 
                </div>
                <div>
                    <div><h4>Seminars/Bootcamps</h4></div>
                    <div><p> We Organise  seminars on emerging issues and happenings around the world  through virtual and physical mode from time to time.</p></div>
                </div>
            </div>

            <div className="Services-Card-Container">
                <div className="Services-Card">
                   <div><img src={training} alt=""/></div> 
                </div>
                <div>
                    <div><h4>Trainings</h4></div>
                    <div><p>We conduct extensive trainings and certification courses on different aspects of Operations Research and Data Analytics packages for Students, Researchers, and practitioners in industries and agencies for efficient decision-making, and professional development.</p></div>
                </div>
            </div>
            <div className="Services-Card-Container">
                <div className="Services-Card">
                   <div><img src={award} alt=""/></div> 
                </div>
                <div>
                    <div><h4>Awards</h4></div>
                    <div><p>The ORIDSAN can award her members and some outstanding individuals in the society at different occasions and functions of the institute from time to time with various meritorious awards.</p></div>
                </div>
            </div>
            
        </div>
        </div>

        {/* we are Everywhere in Nigeria Container */}
        {/* <div className="Statistics-Container" >
            <div><center><h2>WE ARE EVERYWHERE IN NIGERIA</h2></center></div>
            <div className="Stastics-Count-section">
                <div className="Stat-Members">
                <div><img src={user} alt=""/></div> 
                    <div className="Count"><span>3,000 + </span><p>Members</p></div>
                </div>
                <div className="Stats-Local-Chapters">
               <div><img src={chapterIcon} alt=""/></div> 
                    <div className="Count"><span>250 + </span><p>Local Chapters</p></div>
                </div>
                <div className="Stats-States">
                <div><img src={locationIcon} alt=""/></div> 
                    <div className="Count"><span>36 + FCT </span><p>states </p></div>
                </div>
            </div>
        </div> */}
        </>
     );
}
 
export default Services;